import React from "react"
import "../styles/DeftLogo.scss";

function DeftLogo() {
  return (
    <svg className="deft-logo" xmlns="http://www.w3.org/2000/svg" width="80" height="36" viewBox="0 0 798 344.5">
      <defs>
        <title>Deft logo</title>
      </defs>
      <g className="deft-logo__solid">
        <path d="M798,10h-1.4L783,8.7h-34L736.8,10h-20.4L696,12.8l-25.8,2.7l-28.5,4.1l-31.3,6.8l-5.4,2.7h2.7l-1.4,2.7h1.4
          c0,2.7-2.7,1.4-2.7,4.1l-1.4-1.4v2.7c0,2.7,0,5.4,2.7,6.8l1.4,1.4l1.4,4.1v2.7l-13.6,4.1h5.4l-4.1,1.4l-5.4,1.4h6.8
          c-0.1,0.2-0.2,0.4-0.3,0.5c-1.3,2-2.5,0.9-3.8,2.2l4.1,1.4h1.4l2.7,1.4h2.7v1.4h-6.8c0,2.7,2.7,2.7,4.1,4.1l-5.4,1.4v1.4h9.5
          c-1.4,2.7-4.1,0-5.4,2.7l4.1,1.4l-2.7,1.4l1.4,1.4l-5.4,1.4l1.4,1.4l1.4-1.4c2.7,1.4,1.4,2.7,1.4,4.1h13.6
          c-2.7,1.4-6.8,1.4-9.5,4.1l1.4,1.4l2.7,2.7h2.7l8.2-1.4h1.4c2.7-2.7,6.8-2.7,10.9-4.1h4.1c-1.4,4.1,1.4,2.7,4.1,4.1h-8.2
          c1.4,2.7,4.1,1.4,6.8,2.7l-5.4,1.4l-12.2,5.4l-5.4,4.1l2.7,1.4c1.4,1.4,2.7,2.7,5.4,1.4h6.8l1.4-1.4l8.2,1.4l-1.4,5.4v0.3v12
          l-1.4,4.1l-1.4,16.3v2.7l1.4,4.1V165c0,0.5,0.1,0.9,0.1,1.4c0,0.5,0.1,0.9,0.2,1.4c0.3,2.7,0.6,5.4,1,8.1v4.1l1.4,4.1v1.4l1.4-4.1
          v-13.6v-1.4l1.4-27.2l1.4-4.1l1.4-12.2v4.1l1.4,2.7v4.1l1.4-1.4v6.8l1.4,4.1v16.3l1.4,6.8v1.4l0,0l1.4,2.7l1.4,10.9l1.4,4.1v1.4
          l1.4-1.4l-1.4-12.2l0.6-5.4l0.2-1.4l0.6-5.4v-21.8l1.4-2.7H658l1.4-4.1l-1.4-2.7c1.4,0,0-2.7,2.7-2.7v4.1l1.4,4.1v2.7l1.4,1.4v-1.4
          l1.4,2.7l-1.4,1.4c-2.7,0-2.7,1.4-1.4,2.7h2.7c1.4,2.7,0,4.1-2.7,5.4l1.4,1.4l1.4,2.7v12.2c0,0.5-0.1,1-0.1,1.4
          c-0.3,1.8-0.7,3,1.5,4.1v5.4c1.4,1.4,0,2.7-1.4,4.1l1.4,5.4l-1.4,4.1v8.2l1.4,4.1c-2.7,5.4-1.4,9.5-1.4,13.6l-1.4,1.4v3.8v4.3
          l-1.4,1.4l-1.4,1.4l-2.7,2.7l-1.4,1.4l-1.4,4.1l1.4,1.4v8.2l-1.4,13.6l-1.4,12.2v3.6v5.9l0.7,4.1l0.7,4.1c0,4.1-2.7,9.5-1.4,13.6
          c0,6.4-0.9,12.8-2.7,19c-1.4,1.4-2.7,4.1-1.4,6.8h2.7c1.4,2.7,4.1,1.4,6.8,1.4l1.4-2.7v2.7l2.7-5.4l-1.4,6.8l2.7-4.1l1.4-4.1
          l2.7-1.4v1.4h4.1c2.7-1.4,2.7,0,4.1,1.4l2.7,5.4l2.7-1.4v2.7l2.7-1.4v1.4c4.1-2.7,2.7-2.7,8.2,0c0-2.7,2.7-1.4,4.1-2.7v2.7l1.4-2.7
          c2.7,0,2.7-2.7,2.7-4.1v-1.4c2.7-1.4,2.7-4.1,2.7-5.4v-5.4l1.4-6.8l1.4-2.7v-1.4l1.4-13.6l-0.2-2.7l-0.8-10l-0.4-4.9v-19l-1.4-1.4
          v-9.5l-1.4-1.4v-2.7l1.4-1.4v-6.8l-0.3-2.7l-0.4-4.3l-0.7-6.6v-8.2l-1.4-4.1v-2.7l-1.4-2.7l1.4-1.4v-6.8h-4.1l1.4-1.4
          c2.7,0,2.7-1.4,2.7-4.1v-8.2h-1.4c1.4-1.4,2.7-2.7,1.4-4.1l-5.4-1.4l1.4-2.7l0.7-1.4l0.7-1.4h1.4v1.4v1.4l0,0l1.4,2.7l1.4-2.7l0,0
          v-1.4v-2.7l1.4-16.3l1.4-20.4v-13.3v-8.4v-5.4l1.4-5.4l1.4-1.4l1.4-2.7l5.4-1.4l4.1-4.1h-2.7l2.7-2.7v-1.4h5.4l20.4,1.4l13.6,1.4
          l8.2,1.4H779l1.4-1.4l-2.7-1.4l-9.5-2.7l-4.1-2.7l2.7-1.4l-6.8-1.4h4.1l-2.7-1.4l2.7-1.4h1.4l4.1-1.4l-8.2-4.1h1.4l-4.1-5.4
          l1.7-0.8l3.8-1.9h21.8v-1.4c1.4-2.7,1.4-2.7-1.4-4.1l-2.7-1.4v-2.7c-2.7,1.4-2.7-1.4-4.1-2.7L779,44l4.1-4.1l-2.7-2.7h2.7l-4.1-1.4
          c2.7-1.4,1.4-2.7,0-2.7l4.1-2.7l-1.4-1.4l5.4-1.4l-1.4-1.4l5.4-4.1c-1.4-1.4-4.1-2.7-6.8-1.4l-1.4-1.4h-12.2l-1.4-1.4l-1.4-1.4
          l4.1-2.7h13.6l1.4-1.4l-4.1-1.4h15V10z"/>
        <path d="M576.4,45.4l4.1-1.4l15-2.7l6.8-1.4l-1.4-1.4l-8.2,1.4l-8.2-1.4v-1.4h-4.1l-4.1-1.4l8.2-2.7h-4.1l-4.1,1.4h-8.2l-2.7-1.4
          l-5.4,1.4l-1.4-1.4l-2.7,1.4l-1.4-1.4l12.2-2.7H556l1.4-1.4l1.4-1.4h-4.1l-2.7-1.4h-2.7l-1.4-1.4c-2.7,1.4-4.1,0-6.8-1.4l2.7-1.4
          h5.4l4.1-1.4l-8.2-2.7v-2.7l-4.1-1.4c5.4-2.7,12.2-2.7,17.7-4.1l-6.8-1.4l2.7-1.4L537,8.7L516.6,10l2.7-1.4L543.8,6l16.3-1.4V1.9
          l-9.5-1.4c-21.6-1.8-43.4,1-63.9,8.2l-6.8,1.4H469L466.3,6l-1.4,4.1l-1.4-2.7l-2.7,2.7l-2.7-1.4V10c-2.7,0-2.7,0-4.1-2.7l-5.4,1.4
          l-1.4-1.4V10c-2.7,0-5.4,1.4-5.4-2.7l-1.4,1.4l-1.4-1.4c0,2.7-1.4,2.7-2.7,2.7V6L435,7.3l-1.4,1.4h-6.8c0,2.7-4.1,1.4-5.4,4.1
          l6.8,1.4l-8.2,2.7V25l-2.7,1.4l-8.2,2.7h-2.7l-4.1,2.7h2.7l10.9,1.4l1.4,1.4l2.7,2.7v1.4h-1.4l-2.7,1.4l2.7,1.4l-5.4,1.4l5.4,2.7
          h-5.4l-2.7-1.4v1.4v1.4c-1.4-1.4-2.7,0-4.1,1.4h1.4l2.7,1.4h-5.4l2.7,2.7l-4.1,1.4l1.4,2.7l13.6,1.4c0.6,0.6,0.6,1.3,0.4,1.9
          c-0.3,0.7-1,1.5-1.7,2.2l2.7,2.7l1.4,4.1l-1.4,1.4l-1.4,1.4l2.7,1.4l1.4,4.1v9.5l-5.4,1.4l-1.4,1.4h2.7l4.1,1.4v5.4l1.4,2.7v1.4
          l-1.4,2.7l-1.8,2.7l-0.9,1.4l-2.7,1.4l-2.7,1.4h-4.1v1.4h2.7l-6.8,2.7l-1.1,0.3l-4.4,1.1l-2.7,1.4c2.7,0,1.4,2.7,4.1,2.7l-4.1,1.4
          l2.7,1.4h-2.7l-1.4,2.7l-1.4,4.1l-2.7,1.4v2.7l1.4,4.1l1.4,1.4v1.4c1.4,1.4,1.4,2.7,4.1,1.4h6.8l5.4-1.4h10.9v27.2v1.4v5.4l1.4,1.4
          c-2.7,2.7-1.4,4.1-1.4,6.8v10.9c0,1.4-2.7,1.4-1.4,4.1l-1.4,4.1v5.4l-1.4,4.1l-1.1,12l-0.3,3l-0.3,1.4l-1.1,5.4h1.4v-4.1l1.4-1.4
          l2.7,6.8c-2.7,1.4-2.7,2.7-2.7,4.1v9.5l-1.4,9.5v1.4l-1.4,8.2l1.4,2.7v7.7v0.5H416v1.4l1.4,2.7v2.7l1.4,1.4h-1.4l-0.5,1.4l-2.2,5.4
          c-1.4-1.4-1.4,0-2.7,1.4l2.7,1.4c0,2.7-1.4,4.1-4.1,5.4c2.7,1.4,2.7,4.1,2.7,5.4v2.7l1.4-2.7l2.7-1.4l-4.1,5.4l-1.4,1.4
          c0,1.4,0,2.7-2.7,4.1v1.4h1.4v4.1h2.7l-1.4,2.7h1.4l-2.7,1.4c2.7,2.7,1.4,5.4,1.4,6.8l2.7,1.4v1.4h2.7l-1.4,10.9h1.4l1.4-9.5h5.4
          l5.4,1.4v4.1c1.4-2.7,1.4-4.1,4.1-4.1l2.7-1.4l1.4-1.4l1.4,1.4l1.4-4.1l1.4-1.4h8.2l4.1,1.4v1.4l2.7-2.7v2.7h1.4l1.4-2.7h2.7
          l1.4,4.1v-1.4h1.4v-2.7h1.4v4.1l2.7-4.1l1.4-1.4v1.4l1.4,4.1v-5.4l5.4-2.7l1.4-1.4l-1.4-1.4l2.7-1.4l-5.4-2.7l-1.4-1.4l-1.4-4.1
          c0-9.1-1.9-18.1-4.1-27.2c-0.4-1.8-0.9-3.6-1.3-5.4c-0.6-1.3-1.2-2.8-1.6-4.6c-0.4-2-0.5-4.1,0.2-6.3v-12.2l1.4-1.4
          c1.4,0,2.7-1.4,0-1.4v-1.4l1.4-4.1l-1.4,1.4v-1.4l1.4-5.4V233l1.4-6.8v-4.3v-6.6l1.4-1.4v-1.4l1.4-4.1c-1.4-6.8,0-15,1.4-21.8
          l1.4-9.5c0-4.1,2.7-6.8,0-9.5c0,0,0,0,0,0c0.1-0.5,0.1-0.9,0.2-1.4c0.4-2.7,0.8-5.4,1.2-8.2l-1.4-4.1l-2.7,2.7l1.4-20.4h8.2
          l13.6-1.4l10.9-1.4H518l4.1-1.4h4.1l1.4-2.7l1.4-9.5l4.1,4.1l-2.7,2.7l1.4,2.7l1.4,1.4l1.4-2.7h-1.4v1.4c0-1.4-1.4-2.7,1.4-2.7
          v-6.8l0.9-6.5l0.4-3v-5.4v-1.4l-2.7,1.4l-2.7-1.4h-1.4l-1.4-12.2h-6.8L518,93h-6.8l-6.8,1.4v4.1l-28.5,4.1v-6.8l-1.4-2.7l1.4-8.2
          c0-2.7,1.4-4.1,4.1-4.1h1.4l10.9-2.7l5.4-1.4h8.2l15-1.4h12.2l1.4,1.4h6.8l-1.4-1.4l-1.4-1.4l-5.4-1.4l-9.5-1.4l-16.3-1.4L484,71.2
          l-5.4,1.4c-2.7-2.7-1.4-5.4-1.4-6.8h1.4l10.9-1.4h8.2l12.2,1.4l13.6-1.4l19-1.4h10.9l10.9-1.4l-2.7-1.4c0.2-0.3,0.4-0.6,0.6-0.8
          c1.5-1.6,3.7-0.5,4.9-0.5l5.4-1.4l-4.1-1.4c2.7-2.7,5.4,0,8.2-2.7l-5.4-1.4l2.7-1.4l-1.4-1.4c1.4-1.4,4.1,0,5.4-2.7h-8.2
          c1-0.5,1.8-1,2.6-1.4C573.5,44.8,574.7,44.5,576.4,45.4z"/>
        <polygon points="114.2,147.3 111.5,146 110.1,143.9 110.1,144.6 111.5,148.7 112.8,148.7 	"/>
        <path d="M191.7,167.8C191.7,167.7,191.7,167.7,191.7,167.8l0.5-1.4l0.9-2.7l-1.4-4.1v-5.4l-2.7-16.3V131c-1.4-4.1-2.7-6.8-1.4-10.9
          l-1.4-1.4l-1.3-5.2l-0.1-0.3l-3.1-8.2l-1-2.7c-1.4-2.7-2.7-4.1-1.4-6.8l-2.7-5.4l-2.7-4.1l-6.8-9.5l-15-16.3
          c-0.3-0.3-0.6-0.6-0.9-0.8c-5.1-5.1-10.3-9.8-15.7-14.1c-4.3-3.4-8.7-6.6-13.3-9.5l1.4-1.4l-2.7-2.7l-2.7-1.4l-1.4-1.4v-1.4
          L106,20.9c-5.4-4.1-12.2-6.8-17.7-8.2L85.6,10h-1.4l1.4-2.7c0-2.7-2.7-1.4-2.7-2.7L87,0.5h-2.7l-19,4.1h-4.1L39.4,0.5H19l-6.8,1.4
          l-1.4,1.4h1.4L13.6,6h2.7c2.7-1.4,4.1,1.4,5.4,1.4l5.4,1.4v2.7h-1.4c1.4,1.4,4.1,0,4.1,2.7c-2.7,1.4-6.8,1.4-9.5,5.4l4.1,1.4
          l4.1,1.4l5.4,1.4V25h-5.4v2.7l2.7,2.7c1.4,2.7,0,4.1-2.7,4.1l-4.1,1.4h-2.7l1.4,2.7h-2.7l-4.1-1.4v1.4l1.4,1.4l-9.5,1.4L0,44
          c1.8,0,1.8,0.6,1.6,1.4c-0.1,0.4-0.3,0.9-0.3,1.4l5.4,1.4l1.4,1.4H15l2.7,1.4v2.7l-4.1-1.4l1.4,4.1h-2.7l1.4,2.7l0.1,0.5l4,17.2
          L15,78v2.7L13.6,78v6.8h-2.7l1.4,10.9l1.2,9.5l0.2,1.4l-0.2-1.4l-1.1-6.8v2.7l0.4,4.1l0.8,8.4l0.2,2.5c0.7,12.2,1.1,24.5,1.4,36.7
          l1.4,6.8l-1.4,2.7l-0.5,4.1l-0.2,1.4l-0.8,6.8l-1.4,6.8v1.4l-1.4,6.8v4.1l-1.4,2.7v9.5l1.4,5.4l-1.4-1.4l1.4,4.1l1.4,5.4
          c-1.4-2.7-1.4,1.4-2.7,0v2.5v0.2c0.8,1.7,0.1,2.8-0.5,4.1c-0.4,0.8-0.8,1.7-0.8,2.7l2.7-1.4v-1.4h1.4l1.4,19v-1.4l2.7,4.1l1.4,5.4
          l1.4,1.4l1.4,4.1c0,2.7,0,5.4,2.7,6.8c-1.4,1.4-2.7,2.7-1.4,4.1l1.4,1.4v4.9v10v1.4l1.4,5.4v8.2l1.4,6.8v1.4h1.4v-10.9h1.4v-1.4
          l1.4,1.4c0,2.7,0,5.4,2.7,8.2c1.4-1.4,2.7-1.4,2.7,1.4v1.4l1.4,8.2V305h1.4v5.4l1.4,4.1l1.4-4.1l1.4,2.7v-1.4l1.4-9.5V286v-2.7
          c0-3,0-5.2,1.1-7.3c0.4-0.8,0.9-1.5,1.6-2.2l1.4-4.1c2.7-1.4,2.7-2.7,2.7-5.4v-24.5l1.4-10.9v-2.7v-4.3v-13.4l1.4-13.6l2.7-13.6
          l1.4-10.9l0.5-2.7l0.3-1.4l0.5-2.7l1.4-4.1l1.4-6.8l4.1-10.9v4.1l-1.4,9.5l-0.6,10.9l-0.1,1.4L62.5,180v21.8l-1.4,13.6v2.7l1.4-1.4
          l4.1-15l1.4-13.6l2.7-20.4l0,0l0.3-1.4l1-4.1v1.4l0.2,2.7l0.1,1.4l1.1,16.3l1.4,9.5v9.5l2.7,1.4v-6.8l1.4-15l2.8-14.9l0.3-1.4
          l1-5.4l4.1-19l2.7-13.6v-4.1l2.7,1.4l6.8,8.2l1.4,1.4l2.7,1.4l2.7,4.1l3.1,1l1-1c-2.7-1.4-1.4-2.7-1.4-2.7L102,127l10.9,13.6
          l4.1-2.7v-1.4L106,122.9l-7.4-9.3l-3.5-4.3l-1.4-1.4l0.5-2.7l0.9-5.4l5.4,4.1l1.4,1.4l1.4,1.4l4.1,4.1l2,3l0.7,1.1l1.4,1.4l1.4,1.4
          l2.7,4.1v2.7l2.7-2.7l1.4,5.4v4.1l2.7,2.7l-1.4,2.7l2.7,1.4v1.4h-1.4l-1.4,5.4l-1.4,1.4v4.1l1.4,1.4l2.7,1.4v6.8l1.4,1.4l-0.9,5.4
          l-0.2,1.4l-0.2,1.3l-4.1,12.2l2.7-2.7l1.4-1.4c0,5.4-2.7,9.5-5.4,13.6l1.4,1.4c1.3-0.9,2.7-1.8,4.1-2.7l-2.7,4.1h2.7l4.1-2.7
          l1.4-1.4l-1.4,4.1l5.4-6.8l1.4,2.7l1.4-2.7l2.7-4.1v-1.4l1.4,4.1l2.7-4.1V180h1.4l2.7-4.1l1.4-5.4l1.4,2.7l-1.4,9.5l-9.5,21.8
          l-2.7,5.4l-4.1,6.8l-3.9,5.2l-3.2,4.3l-1,1.4l-5.4,8.2l-4.1,5.4l-1.4,2.7l-2.7,2.7l-2.7,4.1l-4.1,2.7v1.4l-2.7,2.7l-4.1,2.7
          l-2.7,4.1l-6.8,6.8l-4.1,2.7v1.4l-1.3,0.9l-2.8,1.9l-4.1,4.1l-1.4,1.4l-2.7,2.7l-4.1,4.1H68l2.7-2.7h2.7v4.1h-1.4l-4.1,5.4
          l-1.4,2.7l-5.4,1.4v1.4l2.7,1.4c0,2.7,2.7,2.7,4.1,2.7l2.7-1.4l13.6-4.1H87l20.4-8.2l10.9-4.1h2.7l1.4,1.4l2.7-1.4l5.4-2.7l4.1-1.4
          l8.2-5.4l3.2-3.2l0.9-0.9c2.7,0,2.7-1.4,4.1-2.7l6.8-6.8l1.4-1.4l1.4-2.7l8.2-12.2l2.7-2.7v2.7l9.5-19c0.5-1.4,1-2.7,1.4-4.1
          c0.5-1.4,0.9-2.9,1.3-4.3c3.4-11,5.7-22.3,6.8-33.7C191.7,181.3,193,174.5,191.7,167.8z"/>
        <path d="M377.9,273.8c-6.8,1.4-15,2.7-21.8,1.4l-15-1.4l-20.4-1.4c-4.1,0-8.2,0-12.2-2.7l-2.7-1.4h-12.2L280,267l-5.4-1.4v-6.8
          l1.4-6.8l2.7-23.1l0.4-2.7l0.6-4.3l1.8-13.4l1.4-23.1l1.2-17.7l0.1-1.4l0.1-1.4v-2.7l-2.7,1.4v2.7v1.4l0,0l-1.3,1.3
          c-2.7,1.4-4.1,2.7-4.1,5.4v4.1l-1.4,4.1l-1.4,2.7l-1.4,1.4l-1.4-5.4l-1.4,9.5l-2.7-6.8l-2.7,2.7c1.4-1.4-1.4-1.4-1.4-1.4v-9.5
          l-1.4-4.1v-4.1v-1.4v-17.7l1.4-19v-12.2h1.4v10.9l1.4,8.2l1.4,1.4l1.4-20.4l28.9,1.3l11.8-1.3l6.8-1.4l2.7-1.4l-1.1-1.1l-0.3-0.3
          h1.4c2.7,0,5.4,0,6.8-2.7l8.2-2.7l2.7-2.7l2.7-2.7h5.4l1.4-2.7l4.1-1.4h-2.7l-1.4-1.4h5.4l-1.4-2.7l4.1-1.4l4.1-4.1h-1.4v-4.1
          l-6.8-1.4H348l-5.4-1.4h-57.1v-6.8h2.7l4.1-2.7l8.2-5.4l9.5-4.1h21.8l21.8,2.7h2.7v-1.4l-5.4-1.4l-5.4-1.4l-4.1-1.4l-3.4-0.8
          l-2.1-0.5l2.7-1.4l13.6-1.4l10.9-1.4l2.7-1.4l-13.6-1.4l-10.9-1.4v-1.4c-1.4,0-2.7,1.4-4.1-1.4l19-1.4h-1.4l2.3-1.4l4.5-2.7
          l-5.4-1.4l-5.4-1.4l2.7-1.4h8.2c2.7,0,4.1-1.4,5.4-4.1h-1.4l1.4-2.7l2.7-1.4l-5.4-1.4l2.7-1.4h-5.4l-1.4-2.7l2.7-1.4
          c2.7,0,4.1-1.4,5.4-4.1l1.4-1.4h-5.4l2.7-1.4H363l2.7-1.4l8.2-2.7l-15,2.7v-1.4l10.9-1.4l-2.7-1.4h-8.2c-2.7-1.4-5.4-1.4-8.2,1.4
          v-1.4h-16.3l1.4-1.4c2.7,1.4,4.1-1.4,6.8-2.7h-4.1V6h5.4l-1.4,1.4l4.1-1.4l1.4-1.4l4.1-1.4H348L323.6,6l-21.8,1.4h-17.7l-15-1.4
          l-15-1.4h-2.7l-20.4,2.7h-20.4l-5.4,4.1l-6.8,5.4l1.4,2.7h1.4c-2.3,3.1-4.6,6.3-6.8,9.5h5.4l9.5-2.7l1.4,1.4v5.4l-1.4,2.7l5.4,1.4
          l-5.4,2.7h4.1v1.4h-4.1l1.4,4.1l4.1-1.4v1.4h-2.7v2.7l6.8,1.4c0,2.7-2.7,1.4-4.1,2.7c1.4,1.4,2.7,0,4.1,2.7c0,1.5,0,3.1,0,4.6
          c-0.1,4.8-0.1,9.6,0,14.4v31.3v1.4l0.4,7.1l1,20.1l1.4,4.1l-1.4,2.7l1.4,5.4l1.4,4.1v2.7l1.4,9.5l0.6,4.1l0.2,1.4l0.6,4.1v4.1
          l1.4,2.7v4.1l1.4,5.4l-1.4,5.4v1.4l-4.1,9.5l-1.4,9.5l-1.4,5.4v2.5v4.3v8.2l-1.4,6.8l1.4,2.7c-0.6,4.7-1.9,9.3-4.1,13.6l-5.4,8.2
          l-2.7,4.1l-1.4,5.4v0.9v3.2l-1.4,2.7h-2.7c0,4.1-1.4,4.1-4.1,4.1H193l-1.4,1.4l-1.4,2.7l-2.7,1.4l1.4,1.4v1.4l2.7,1.4l2.7,1.4
          l4.1,2.7l1.4,1.4l-5.4,1.4h5.4v1.4h-2.7c1.4,2.7,4.1,0,5.4,2.7l1.4,2.7h1.4v2.7l5.4,1.4l1.4,1.4h4.1c1.4,2.7,1.4,2.7,4.1,2.7v2.7
          h-2.7l5.4,2.7c1.4-2.7,1.4-2.7,2.7-1.4l-1.4,1.4h2.7l1.4-1.4l6.8,1.4V335l1.4-12.2c4.1-1.4,5.4-1.4,5.4,2.7c2.7,0,0-2.7,2.7-4.1
          l1.4,2.7c0-2.7,1.4-1.4,2.7-1.4c2.5-1.3,2.7-1.3,3.8,0.8l0.3-0.8l4.1-1.4l12.2-1.4h6.8l4.1-1.4h2.7l-2.7-2.7
          c-1.4,0-4.1,1.4-4.1-1.4h10.9v-1.4h-2.7l2.7-1.4h17.7l16.3,2.7h4.1l-2.7-2.7h2.7l5.4-1.4l1.4-1.4h4.1l2.7-1.4
          c-1.4-1.4-2.7-1.4-1.4-2.7l-1.4-1.4c1.4-2.7,2.7-1.4,2.7-1.4c6.8-2.7,15-4.1,21.8-4.1v-2.7l-5.4-1.4l1.4-2.7l-1.4-5.4l17.7-2.7
          l8.2-1.4l8.2-2.7l6.8-2.7l0.5-0.5l0.9-0.9l4.1-4.1l2.7-2.7h-4.1L377.9,273.8z"/>
        <polygon points="251.5,326.8 252.2,324.8 251.5,325.4 	"/>
      </g>
      <g className="deft-logo__striped">
        <g className="deft-logo__bar-1">
          <path d="M764,57.6h21.8v-1.4c1.4-2.7,1.4-2.7-1.4-4.1l-2.7-1.4v-2.7c-2.7,1.4-2.7-1.4-4.1-2.7L779,44l4.1-4.1l-2.7-2.7h2.7l-4.1-1.4c2.7-1.4,1.4-2.7,0-2.7l4.1-2.7l-1.4-1.4l5.4-1.4l-1.4-1.4l5.4-4.1c-1.4-1.4-4.1-2.7-6.8-1.4l-1.4-1.4h-12.2l-1.4-1.4l-1.4-1.4l4.1-2.7h13.6l1.4-1.4l-4.1-1.4h15V10h-1.4L783,8.7h-34L736.8,10h-20.4L696,12.8l-25.8,2.7l-28.5,4.1l-31.3,6.8l-5.4,2.7h2.7l-1.4,2.7h1.4c0,2.7-2.7,1.4-2.7,4.1l-1.4-1.4v2.7c0,2.7,0,5.4,2.7,6.8l1.4,1.4l1.4,4.1v2.7l-13.6,4.1h5.4l-4.1,1.4l-5.4,1.4h6.8c-0.1,0.2-0.2,0.4-0.3,0.5h162.4L764,57.6z"/>
          <path d="M566.9,59l5.4-1.4l-4.1-1.4c2.7-2.7,5.4,0,8.2-2.7l-5.4-1.4l2.7-1.4l-1.4-1.4c1.4-1.4,4.1,0,5.4-2.7h-8.2c1-0.5,1.8-1,2.6-1.4c1.3-0.6,2.5-0.9,4.2,0l4.1-1.4l15-2.7l6.8-1.4l-1.4-1.4l-8.2,1.4l-8.2-1.4v-1.4h-4.1l-4.1-1.4l8.2-2.7h-4.1l-4.1,1.4h-8.2l-2.7-1.4l-5.4,1.4l-1.4-1.4l-2.7,1.4l-1.4-1.4l12.2-2.7H556l1.4-1.4l1.4-1.4h-4.1l-2.7-1.4h-2.7l-1.4-1.4c-2.7,1.4-4.1,0-6.8-1.4l2.7-1.4h5.4l4.1-1.4l-8.2-2.7v-2.7l-4.1-1.4c5.4-2.7,12.2-2.7,17.7-4.1l-6.8-1.4l2.7-1.4L537,8.7L516.6,10l2.7-1.4L543.8,6l16.3-1.4V1.9l-9.5-1.4c-21.6-1.8-43.4,1-63.9,8.2l-6.8,1.4H469L466.3,6l-1.4,4.1l-1.4-2.7l-2.7,2.7l-2.7-1.4V10c-2.7,0-2.7,0-4.1-2.7l-5.4,1.4l-1.4-1.4V10c-2.7,0-5.4,1.4-5.4-2.7l-1.4,1.4l-1.4-1.4c0,2.7-1.4,2.7-2.7,2.7V6L435,7.3l-1.4,1.4h-6.8c0,2.7-4.1,1.4-5.4,4.1l6.8,1.4l-8.2,2.7V25l-2.7,1.4l-8.2,2.7h-2.7l-4.1,2.7h2.7l10.9,1.4l1.4,1.4l2.7,2.7v1.4h-1.4l-2.7,1.4l2.7,1.4l-5.4,1.4l5.4,2.7h-5.4l-2.7-1.4v1.4v1.4c-1.4-1.4-2.7,0-4.1,1.4h1.4l2.7,1.4h-5.4l2.7,2.7l-4.1,1.4l1.4,2.7l13.6,1.4c0.6,0.6,0.6,1.3,0.4,1.9H562C563.5,57.9,565.7,59,566.9,59z"/>
          <path d="M135.7,45.4c-4.3-3.4-8.7-6.6-13.3-9.5l1.4-1.4l-2.7-2.7l-2.7-1.4l-1.4-1.4v-1.4L106,20.9c-5.4-4.1-12.2-6.8-17.7-8.2L85.6,10h-1.4l1.4-2.7c0-2.7-2.7-1.4-2.7-2.7L87,0.5h-2.7l-19,4.1h-4.1L39.4,0.5H19l-6.8,1.4l-1.4,1.4h1.4L13.6,6h2.7c2.7-1.4,4.1,1.4,5.4,1.4l5.4,1.4v2.7h-1.4c1.4,1.4,4.1,0,4.1,2.7c-2.7,1.4-6.8,1.4-9.5,5.4l4.1,1.4l4.1,1.4
          l5.4,1.4V25h-5.4v2.7l2.7,2.7c1.4,2.7,0,4.1-2.7,4.1l-4.1,1.4h-2.7l1.4,2.7h-2.7l-4.1-1.4v1.4l1.4,1.4l-9.5,1.4L0,44c1.8,0,1.8,0.6,1.6,1.4c-0.1,0.4-0.3,0.9-0.3,1.4l5.4,1.4l1.4,1.4H15l2.7,1.4v2.7l-4.1-1.4l1.4,4.1h-2.7l1.4,2.7l0.1,0.5h137.7C146.2,54.4,141.1,49.7,135.7,45.4z"/>
          <path d="M335.8,59l2.7-1.4l13.6-1.4l10.9-1.4l2.7-1.4l-13.6-1.4l-10.9-1.4v-1.4c-1.4,0-2.7,1.4-4.1-1.4l19-1.4h-1.4l2.3-1.4l4.5-2.7l-5.4-1.4l-5.4-1.4l2.7-1.4h8.2c2.7,0,4.1-1.4,5.4-4.1h-1.4l1.4-2.7l2.7-1.4l-5.4-1.4l2.7-1.4h-5.4l-1.4-2.7l2.7-1.4c2.7,0,4.1-1.4,5.4-4.1l1.4-1.4h-5.4l2.7-1.4H363l2.7-1.4l8.2-2.7l-15,2.7v-1.4l10.9-1.4l-2.7-1.4h-8.2c-2.7-1.4-5.4-1.4-8.2,1.4v-1.4h-16.3l1.4-1.4c2.7,1.4,4.1-1.4,6.8-2.7h-4.1V6h5.4l-1.4,1.4l4.1-1.4l1.4-1.4l4.1-1.4H348L323.6,6l-21.8,1.4h-17.7l-15-1.4l-15-1.4h-2.7l-20.4,2.7h-20.4l-5.4,4.1l-6.8,5.4l1.4,2.7h1.4c-2.3,3.1-4.6,6.3-6.8,9.5h5.4l9.5-2.7l1.4,1.4v5.4l-1.4,2.7l5.4,1.4l-5.4,2.7h4.1v1.4h-4.1l1.4,4.1l4.1-1.4v1.4h-2.7v2.7l6.8,1.4c0,2.7-2.7,1.4-4.1,2.7c1.4,1.4,2.7,0,4.1,2.7c0,1.5,0,3.1,0,4.6h119L335.8,59z"/>
        </g>
        <g className="deft-logo__bar-2">
          <path d="M420.1,64.4l1.4,4.1l-1.4,1.4l-1.4,1.4l2.7,1.4l1.4,4.1v9.5l-5.4,1.4l-1.4,1.4h2.7l4.1,1.4v5.4l1.4,2.7v1.4l-1.4,2.7l-1.8,2.7l-0.9,1.4l-2.7,1.4l-2.7,1.4h-4.1v1.4h2.7l-6.8,2.7l-1.1,0.3h129.8l0.4-3v-5.4v-1.4l-2.7,1.4l-2.7-1.4h-1.4l-1.4-12.2h-6.8L518,93h-6.8l-6.8,1.4v4.1l-28.5,4.1v-6.8l-1.4-2.7l1.4-8.2c0-2.7,1.4-4.1,4.1-4.1h1.4l10.9-2.7l5.4-1.4h8.2l15-1.4h12.2l1.4,1.4h6.8l-1.4-1.4l-1.4-1.4l-5.4-1.4l-9.5-1.4l-16.3-1.4L484,71.2l-5.4,1.4c-2.7-2.7-1.4-5.4-1.4-6.8h1.4l10.9-1.4h8.2l12.2,1.4l13.6-1.4l19-1.4h10.9l10.9-1.4l-2.7-1.4c0.2-0.3,0.4-0.6,0.6-0.8H419.1c-0.3,0.7-1,1.5-1.7,2.2L420.1,64.4z"/>
          <path d="M218.9,105.2v1.4l0.4,7.1H317l-0.3-0.3h1.4c2.7,0,5.4,0,6.8-2.7l8.2-2.7l2.7-2.7l2.7-2.7h5.4l1.4-2.7l4.1-1.4h-2.7l-1.4-1.4h5.4l-1.4-2.7l4.1-1.4l4.1-4.1h-1.4v-4.1l-6.8-1.4H348l-5.4-1.4h-57.1v-6.8h2.7l4.1-2.7l8.2-5.4l9.5-4.1h21.8l21.8,2.7h2.7v-1.4l-5.4-1.4l-5.4-1.4l-4.1-1.4l-3.4-0.8h-119c-0.1,4.8-0.1,9.6,0,14.4V105.2z"/>
          <path d="M594.1,61.7l4.1,1.4h1.4l2.7,1.4h2.7v1.4h-6.8c0,2.7,2.7,2.7,4.1,4.1l-5.4,1.4v1.4h9.5c-1.4,2.7-4.1,0-5.4,2.7l4.1,1.4l-2.7,1.4l1.4,1.4l-5.4,1.4l1.4,1.4l1.4-1.4c2.7,1.4,1.4,2.7,1.4,4.1h13.6c-2.7,1.4-6.8,1.4-9.5,4.1l1.4,1.4l2.7,2.7h2.7l8.2-1.4h1.4c2.7-2.7,6.8-2.7,10.9-4.1h4.1c-1.4,4.1,1.4,2.7,4.1,4.1h-8.2c1.4,2.7,4.1,1.4,6.8,2.7l-5.4,1.4l-12.2,5.4l-5.4,4.1l2.7,1.4c1.4,1.4,2.7,2.7,5.4,1.4h6.8l1.4-1.4l8.2,1.4l-1.4,5.4v0.3h66.6v-8.4v-5.4l1.4-5.4l1.4-1.4l1.4-2.7l5.4-1.4l4.1-4.1h-2.7l2.7-2.7v-1.4h5.4l20.4,1.4l13.6,1.4l8.2,1.4H779l1.4-1.4l-2.7-1.4l-9.5-2.7l-4.1-2.7l2.7-1.4l-6.8-1.4h4.1l-2.7-1.4l2.7-1.4h1.4l4.1-1.4l-8.2-4.1h1.4l-4.1-5.4l1.7-0.8H597.8C596.6,61.5,595.3,60.4,594.1,61.7z"/>
          <path d="M95.2,109.3l-1.4-1.4l0.5-2.7l0.9-5.4l5.4,4.1l1.4,1.4l1.4,1.4l4.1,4.1l2,3H185l-0.1-0.3l-3.1-8.2l-1-2.7c-1.4-2.7-2.7-4.1-1.4-6.8l-2.7-5.4l-2.7-4.1l-6.8-9.5l-15-16.3c-0.3-0.3-0.6-0.6-0.9-0.8H13.7l4,17.2L15,78v2.7L13.6,78v6.8h-2.7l1.4,10.9l1.2,9.5l0.2,1.4l-0.2-1.4l-1.1-6.8v2.7l0.4,4.1l0.8,8.4h85.3L95.2,109.3z"/>
        </g>
        <g className="deft-logo__bar-3">
          <polygon points="284.1,165 284.1,162.3 281.4,163.7 281.4,166.4 281.4,167.7 281.4,167.8 283.9,167.8 284,166.4 	"/>
          <path d="M640.3,125.6l-1.4,4.1l-1.4,16.3v2.7l1.4,4.1V165c0,0.5,0.1,0.9,0.1,1.4c0,0.5,0.1,0.9,0.2,1.4h3.8v-1.4l1.4-27.2l1.4-4.1l1.4-12.2v4.1l1.4,2.7v4.1l1.4-1.4v6.8l1.4,4.1v16.3l1.4,6.8v1.4l0,0h4.7l0.2-1.4l0.6-5.4v-21.8l1.4-2.7H658l1.4-4.1l-1.4-2.7c1.4,0,0-2.7,2.7-2.7v4.1l1.4,4.1v2.7l1.4,1.4v-1.4l1.4,2.7l-1.4,1.4c-2.7,0-2.7,1.4-1.4,2.7h2.7c1.4,2.7,0,4.1-2.7,5.4l1.4,1.4l1.4,2.7v12.2c0,0.5-0.1,1-0.1,1.4h34.1l0.7-1.4l0.7-1.4h1.4v1.4v1.4l0,0h2.7l0,0v-1.4v-2.7l1.4-16.3l1.4-20.4v-13.3h-66.6V125.6z"/>
          <path d="M57.9,166.4l0.5-2.7l1.4-4.1l1.4-6.8l4.1-10.9v4.1l-1.4,9.5l-0.6,10.9l-0.1,1.4h7.5l0,0l0.3-1.4l1-4.1v1.4l0.2,2.7l0.1,1.4h9.3l0.3-1.4l1-5.4l4.1-19l2.7-13.6v-4.1l2.7,1.4l6.8,8.2l1.4,1.4l2.7,1.4l2.7,4.1l3.1,1l1-1c-2.7-1.4-1.4-2.7-1.4-2.7L102,127l10.9,13.6l4.1-2.7v-1.4L106,122.9l-7.4-9.3H13.4l0.2,2.5c0.7,12.2,1.1,24.5,1.4,36.7l1.4,6.8l-1.4,2.7l-0.5,4.1l-0.2,1.4h43.3L57.9,166.4z"/>
          <path d="M111.5,116.1l1.4,1.4l2.7,4.1v2.7l2.7-2.7l1.4,5.4v4.1l2.7,2.7l-1.4,2.7l2.7,1.4v1.4h-1.4l-1.4,5.4l-1.4,1.4v4.1l1.4,1.4l2.7,1.4v6.8l1.4,1.4l-0.9,5.4l-0.2,1.4h67.7c0,0,0,0,0,0l0.5-1.4l0.9-2.7l-1.4-4.1v-5.4l-2.7-16.3V131c-1.4-4.1-2.7-6.8-1.4-10.9l-1.4-1.4l-1.3-5.2h-75.6l0.7,1.1L111.5,116.1z"/>
          <polygon points="221.6,137.8 220.2,140.5 221.6,146 223,150.1 223,152.8 224.3,162.3 224.9,166.4 225.1,167.8 261,167.8 261,166.4 261,148.7 262.4,129.7 262.4,117.4 263.7,117.4 263.7,128.3 265.1,136.5 266.5,137.8 267.8,117.4 296.8,118.8 308.6,117.4 315.4,116.1 318.1,114.7 317,113.6 219.2,113.6 220.2,133.8 	"/>
          <polygon points="110.1,143.9 110.1,144.6 111.5,148.7 112.8,148.7 114.2,147.3 111.5,146 	"/>
          <path d="M398.3,116.1c2.7,0,1.4,2.7,4.1,2.7l-4.1,1.4l2.7,1.4h-2.7l-1.4,2.7l-1.4,4.1l-2.7,1.4v2.7l1.4,4.1l1.4,1.4v1.4c1.4,1.4,1.4,2.7,4.1,1.4h6.8l5.4-1.4h10.9v27.2v1.4h51.7c0,0,0,0,0,0c0.1-0.5,0.1-0.9,0.2-1.4c0.4-2.7,0.8-5.4,1.2-8.2l-1.4-4.1l-2.7,2.7l1.4-20.4h8.2l13.6-1.4l10.9-1.4H518l4.1-1.4h4.1l1.4-2.7l1.4-9.5l4.1,4.1l-2.7,2.7l1.4,2.7l1.4,1.4l1.4-2.7h-1.4v1.4c0-1.4-1.4-2.7,1.4-2.7v-6.8l0.9-6.5H405.4l-4.4,1.1L398.3,116.1z"/>
        </g>
        <g className="deft-logo__bar-4">
          <path d="M666.1,171.8v5.4c1.4,1.4,0,2.7-1.4,4.1l1.4,5.4l-1.4,4.1v8.2l1.4,4.1c-2.7,5.4-1.4,9.5-1.4,13.6l-1.4,1.4v3.8h41.4l-0.7-6.6v-8.2l-1.4-4.1v-2.7l-1.4-2.7l1.4-1.4v-6.8h-4.1l1.4-1.4c2.7,0,2.7-1.4,2.7-4.1v-8.2h-1.4c1.4-1.4,2.7-2.7,1.4-4.1l-5.4-1.4l1.4-2.7h-34.1C664.4,169.6,663.9,170.7,666.1,171.8z"/>
          <path d="M51.7,208.5l1.4-13.6l2.7-13.6l1.4-10.9l0.5-2.7H14.3l-0.8,6.8l-1.4,6.8v1.4l-1.4,6.8v4.1l-1.4,2.7v9.5l1.4,5.4l-1.4-1.4l1.4,4.1l1.4,5.4c-1.4-2.7-1.4,1.4-2.7,0v2.5h42.1V208.5z"/>
          <polygon points="62.5,201.7 61.2,215.3 61.2,218 62.5,216.7 66.6,201.7 68,188.1 70.7,167.8 63.2,167.8 62.5,180 	"/>
          <polygon points="655.3,181.3 656.6,185.4 656.6,186.8 658,185.4 656.6,173.2 657.2,167.8 652.5,167.8 653.9,170.5 	"/>
          <path d="M640.3,180l1.4,4.1v1.4l1.4-4.1v-13.6h-3.8c0.3,2.7,0.6,5.4,1,8.1V180z"/>
          <polygon points="702.8,170.5 704.2,167.8 701.5,167.8 	"/>
          <path d="M470.4,214v-1.4l1.4-4.1c-1.4-6.8,0-15,1.4-21.8l1.4-9.5c0-4.1,2.7-6.8,0-9.5h-51.7v5.4l1.4,1.4c-2.7,2.7-1.4,4.1-1.4,6.8v10.9c0,1.4-2.7,1.4-1.4,4.1l-1.4,4.1v5.4l-1.4,4.1l-1.1,12H469v-6.6L470.4,214z"/>
          <path d="M282.8,185.4l1.2-17.7h-2.5l-1.3,1.3c-2.7,1.4-4.1,2.7-4.1,5.4v4.1l-1.4,4.1l-1.4,2.7l-1.4,1.4l-1.4-5.4l-1.4,9.5l-2.7-6.8l-2.7,2.7c1.4-1.4-1.4-1.4-1.4-1.4v-9.5l-1.4-4.1v-4.1h-35.9l0.6,4.1v4.1l1.4,2.7v4.1l1.4,5.4l-1.4,5.4v1.4l-4.1,9.5l-1.4,9.5l-1.4,5.4v2.5h59.4l1.8-13.4L282.8,185.4z"/>
          <path d="M191.7,167.8h-67.7l-0.2,1.3l-4.1,12.2l2.7-2.7l1.4-1.4c0,5.4-2.7,9.5-5.4,13.6l1.4,1.4c1.3-0.9,2.7-1.8,4.1-2.7l-2.7,4.1h2.7l4.1-2.7l1.4-1.4l-1.4,4.1l5.4-6.8l1.4,2.7l1.4-2.7l2.7-4.1v-1.4l1.4,4.1l2.7-4.1V180h1.4l2.7-4.1l1.4-5.4l1.4,2.7l-1.4,9.5l-9.5,21.8l-2.7,5.4l-4.1,6.8l-3.9,5.2h55.6c3.4-11,5.7-22.3,6.8-33.7C191.7,181.3,193,174.5,191.7,167.8z"/>
          <polygon points="74.8,193.6 74.8,203.1 77.5,204.4 77.5,197.6 78.8,182.7 81.6,167.8 72.3,167.8 73.4,184.1 	"/>
        </g>
        <g className="deft-logo__bar-5">
          <path d="M150.9,272.4l6.8-6.8l1.4-1.4l1.4-2.7l8.2-12.2l2.7-2.7v2.7l9.5-19c0.5-1.4,1-2.7,1.4-4.1c0.5-1.4,0.9-2.9,1.3-4.3H128l-3.2,4.3l-1,1.4l-5.4,8.2l-4.1,5.4l-1.4,2.7l-2.7,2.7l-2.7,4.1l-4.1,2.7v1.4l-2.7,2.7l-4.1,2.7l-2.7,4.1l-6.8,6.8l-4.1,2.7v1.4l-1.3,0.9H146l0.9-0.9C149.5,275.1,149.5,273.8,150.9,272.4z"/>
          <polygon points="706.9,252 705.6,250.7 705.6,241.1 704.2,239.8 704.2,237.1 705.6,235.7 705.6,228.9 705.3,226.2 704.9,221.9 663.4,221.9 663.4,226.2 662.1,227.6 660.7,228.9 658,231.6 656.6 233 655.3,237.1 656.6,238.4 656.6,246.6 655.3,260.2 653.9,272.4 653.9,276 707.3,276 706.9,271.1 	"/>
          <path d="M401,271.1l2.7-2.7h-4.1l-21.8,5.4c-6.8,1.4-15,2.7-21.8,1.4l-15-1.4l-20.4-1.4c-4.1,0-8.2,0-12.2-2.7l-2.7-1.4h-12.2L280,267l-5.4-1.4v-6.8l1.4-6.8l2.7-23.1l0.4-2.7l0.6-4.3h-59.4v4.3v8.2l-1.4,6.8l1.4,2.7c-0.6,4.7-1.9,9.3-4.1,13.6l-5.4,8.2l-2.7,4.1l-1.4,5.4v0.9h189.5l0.9-0.9L401,271.1z"/>
          <path d="M464.9,269.7v-12.2l1.4-1.4c1.4,0,2.7-1.4,0-1.4v-1.4l1.4-4.1l-1.4,1.4v-1.4l1.4-5.4V233l1.4-6.8v-4.3h-51.4l-0.3,3l-0.3,1.4l-1.1,5.4h1.4v-4.1l1.4-1.4l2.7,6.8c-2.7,1.4-2.7,2.7-2.7,4.1v9.5l-1.4,9.5v1.4l-1.4,8.2l1.4,2.7v7.7h47.3C464.3,274,464.2,271.9,464.9,269.7z"/>
          <path d="M47.6,269.7c2.7-1.4,2.7-2.7,2.7-5.4v-24.5l1.4-10.9v-2.7v-4.3H9.5v0.2c0.8,1.7,0.1,2.8-0.5,4.1c-0.4,0.8-0.8,1.7-0.8,2.7l2.7-1.4v-1.4h1.4l1.4,19v-1.4l2.7,4.1l1.4,5.4l1.4,1.4l1.4,4.1c0,2.7,0,5.4,2.7,6.8c-1.4,1.4-2.7,2.7-1.4,4.1l1.4,1.4v4.9h21.5c0.4-0.8,0.9-1.5,1.6-2.2L47.6,269.7z"/>
        </g>
        <g className="deft-logo__bar-6">
          <path d="M78.8,277.9l-4.1,4.1l-1.4,1.4l-2.7,2.7l-4.1,4.1H68l2.7-2.7h2.7v4.1h-1.4l-4.1,5.4l-1.4,2.7l-5.4,1.4v1.4l2.7,1.4c0,2.7,2.7,2.7,4.1,2.7l2.7-1.4l13.6-4.1H87l20.4-8.2l10.9-4.1h2.7l1.4,1.4l2.7-1.4l5.4-2.7l4.1-1.4l8.2-5.4l3.2-3.2H81.6L78.8,277.9z"/>
          <path d="M23.1,286v1.4l1.4,5.4v8.2l1.4,6.8v1.4h1.4v-10.9h1.4v-1.4l1.4,1.4c0,2.7,0,5.4,2.7,8.2
          c1.4-1.4,2.7-1.4,2.7,1.4v1.4l1.4,8.2V305h1.4v5.4l1.4,4.1l1.4-4.1l1.4,2.7v-1.4l1.4-9.5V286v-2.7c0-3,0-5.2,1.1-7.3H23.1V286z"/>
          <polygon points="251.5,326.8 252.2,324.8 251.5,325.4 	"/>
          <path d="M653.9,281.9l0.7,4.1l0.7,4.1c0,4.1-2.7,9.5-1.4,13.6c0,6.4-0.9,12.8-2.7,19c-1.4,1.4-2.7,4.1-1.4,6.8h2.7c1.4,2.7,4.1,1.4,6.8,1.4l1.4-2.7v2.7l2.7-5.4l-1.4,6.8l2.7-4.1l1.4-4.1l2.7-1.4v1.4h4.1c2.7-1.4,2.7,0,4.1,1.4l2.7,5.4l2.7-1.4v2.7l2.7-1.4v1.4c4.1-2.7,2.7-2.7,8.2,0c0-2.7,2.7-1.4,4.1-2.7v2.7l1.4-2.7c2.7,0,2.7-2.7,2.7-4.1v-1.4c2.7-1.4,2.7-4.1,2.7-5.4v-5.4l1.4-6.8l1.4-2.7v-1.4l1.4-13.6l-0.2-2.7l-0.8-10h-53.4V281.9z"/>
          <path d="M417.4,276.5H416v1.4l1.4,2.7v2.7l1.4,1.4h-1.4l-0.5,1.4l-2.2,5.4c-1.4-1.4-1.4,0-2.7,1.4l2.7,1.4c0,2.7-1.4,4.1-4.1,5.4c2.7,1.4,2.7,4.1,2.7,5.4v2.7l1.4-2.7l2.7-1.4l-4.1,5.4l-1.4,1.4c0,1.4,0,2.7-2.7,4.1v1.4h1.4v4.1h2.7l-1.4,2.7h1.4l-2.7,1.4c2.7,2.7,1.4,5.4,1.4,6.8l2.7,1.4v1.4h2.7l-1.4,10.9h1.4l1.4-9.5h5.4l5.4,1.4v4.1c1.4-2.7,1.4-4.1,4.1-4.1l2.7-1.4l1.4-1.4l1.4,1.4l1.4-4.1l1.4-1.4h8.2l4.1,1.4v1.4l2.7-2.7v2.7h1.4l1.4-2.7h2.7l1.4,4.1v-1.4h1.4v-2.7h1.4v4.1l2.7-4.1l1.4-1.4v1.4l1.4,4.1v-5.4l5.4-2.7l1.4-1.4l-1.4-1.4l2.7-1.4l-5.4-2.7l-1.4-1.4l-1.4-4.1c0-9.1-1.9-18.1-4.1-27.2c-0.4-1.8-0.9-3.6-1.3-5.4c-0.6-1.3-1.2-2.8-1.6-4.6h-47.3V276.5z"/>
          <path d="M206.6,279.2l-1.4,2.7h-2.7c0,4.1-1.4,4.1-4.1,4.1H193l-1.4,1.4l-1.4,2.7l-2.7,1.4l1.4,1.4v1.4l2.7,1.4l2.7,1.4l4.1,2.7l1.4,1.4l-5.4,1.4h5.4v1.4h-2.7c1.4,2.7,4.1,0,5.4,2.7l1.4,2.7h1.4v2.7l5.4,1.4l1.4,1.4h4.1c1.4,2.7,1.4,2.7,4.1,2.7v2.7h-2.7l5.4,2.7c1.4-2.7,1.4-2.7,2.7-1.4l-1.4,1.4h2.7l1.4-1.4l6.8,1.4V335l1.4-12.2c4.1-1.4,5.4-1.4,5.4,2.7c2.7,0,0-2.7,2.7-4.1l1.4,2.7c0-2.7,1.4-1.4,2.7-1.4c2.5-1.3,2.7-1.3,3.8,0.8l0.3-0.8l4.1-1.4l12.2-1.4h6.8l4.1-1.4h2.7l-2.7-2.7c-1.4,0-4.1,1.4-4.1-1.4h10.9v-1.4h-2.7l2.7-1.4h17.7l16.3,2.7h4.1l-2.7-2.7h2.7l5.4-1.4l1.4-1.4h4.1l2.7-1.4c-1.4-1.4-2.7-1.4-1.4-2.7l-1.4-1.4c1.4-2.7,2.7-1.4,2.7-1.4c6.8-2.7,15-4.1,21.8-4.1v-2.7l-5.4-1.4l1.4-2.7l-1.4-5.4l17.7-2.7l8.2-1.4l8.2-2.7l6.8-2.7l0.5-0.5H206.6V279.2z"/>
        </g>
      </g>
    </svg>
  )
}

export default DeftLogo;
