import React from "react"
import "../styles/MenuButton.scss";

class MenuButton extends React.Component {
  render() {
    return (
      <button
        id="aria_menu-button"
        className="menu-button"
        onClick={this.props.handleMenuOpen}
        aria-haspopup="menu"
        aria-controls="aria_menu"
        aria-expanded={this.props.menuState}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 19.8 16.8">
          <defs>
            <title>Menu</title>
          </defs>
          <rect width="19.8" height="3.5" />
          <rect y="6.6" width="19.8" height="3.5" />
          <rect y="13.3" width="19.8" height="3.5" />
        </svg>
      </button>
    );
  }
}

export default MenuButton;