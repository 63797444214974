import React from "react";
import HomeHeader from "../components/HomeHeader";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";

import "../styles/base/breakpoints.scss";
import "../styles/base/layout.scss";
import "../styles/base/typography.scss";
import "../styles/base/forms.scss";
import "../styles/base/buttons.scss";
import "../styles/base/media.scss";
import "../styles/base/code.scss";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
  }

  handleMenuOpen = () => {
    this.setState({
      isMenuOpen: true,
    });
  }

  handleMenuClose = () => {
    this.setState({
      isMenuOpen: false,
    });
  }

  render() {
    const { location, pageName, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let template;

    if (location.pathname === rootPath) {
      template = (
        <div>
          <HomeHeader
          handleMenuOpen={this.handleMenuOpen.bind(this)}
          handleMenuClose={this.handleMenuClose.bind(this)}
           />
          <main>
            {children}
          </main>
        </div>
      )

    } else {
      template = (
        <div>
          <PageHeader
            handleMenuOpen={this.handleMenuOpen.bind(this)}
            handleMenuClose={this.handleMenuClose.bind(this)}
          />
          {children}
          <PageFooter />
        </div>
      )
    }
    return (
      <div className={this.state.isMenuOpen ? `layout layout--${pageName} menu--is-open` : `layout layout--${pageName}`}>
        {template}
      </div>
    )
  }
}

export default Layout
