import React from "react";
import AGLLCBanner from "../../content/assets/images/AGLCC_LOGO_color.jpg";
import "../styles/PageFooter.scss";

function PageFooter() {
  return (
    <footer className="page-footer">
      <a href="http://atlantagaychamber.org/" target="_blank" rel="noopener noreferrer">
        <img className="page-footer__banner-image" src={AGLLCBanner} />
      </a>
      <p className="page-footer__info">
        <span className="page-footer__copyright">&copy; 2019 Deft, Inc</span>
      </p>
    </footer>
  )
}

export default PageFooter