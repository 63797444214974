import React from "react";
import HollowLogo from "../../content/assets/images/deft-hollow-logo.svg";
import PageNavigation from "../components/PageNavigation";
import MenuButton from "../components/MenuButton";
import "../styles/HomeHeader.scss";

class HomeHeader extends React.Component {
  render() {
    return (
      <header className="home-header">
        <MenuButton
          menuState={this.props.menuState}
          handleMenuOpen={this.props.handleMenuOpen}
        />
        <div>
          <div className="home-header__logo-container">
            <div className="home-header__rainbow-background" />
            <img src={HollowLogo} alt="Deft Logo" className="home-header__logo" />
          </div>
          <h2 className="home-header__title">Ship better software faster.</h2>
          <h3 className="home-header__tagline">
            Deft helps technical executives at high-growth SaaS companies guide their web development teams to quicker delivery, with fewer bugs.
          </h3>
        </div>
        <PageNavigation handleMenuClose={this.props.handleMenuClose} />
      </header>
    )
  }
}

export default HomeHeader;
