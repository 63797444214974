import React from "react";
import { Link } from "gatsby";
import "../styles/PageNavigation.scss";

class PageNavigation extends React.Component {
  render() {
    return (
      <nav className="page-navigation">
        <button className="page-navigation__close-menu-button" onClick={this.props.handleMenuClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18"
            height="18" viewBox="0 0 13.2 13.2">
            <defs>
              <title>Close Menu</title>
            </defs>
            <path d="M12.9,0.3c-0.4-0.4-1-0.4-1.4,0L6.6,5.2L1.7,0.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.9,4.9l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L6.6,8l4.9,4.9c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L8,6.6l4.9-4.9C13.3,1.3,13.3,0.7,12.9,0.3z"/>
          </svg>
        </button>
        <ul id="aria_menu" aria-labelledby="aria_menu-button" role="menu">
          <li role="none">
            <Link to="/consulting/" role="menuitem">
              Consulting
            </Link>
          </li>
          <li role="none">
            <Link to="/team/" role="menuitem">
              Team
            </Link>
          </li>
          <li role="none">
            <Link to="/blog/" role="menuitem">
              Blog
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default PageNavigation
